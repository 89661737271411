// import merge from "lodash/merge";
import { wpClient } from "../wp-client";

export default {
  async create(name, quantity = 0) {
    const body = { name, quantity };
    try {
      const response = await wpClient.post("sitio/v1/plan/order", body);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data.message);
    }
  },
  async capture(paypalOrderID) {
    try {
      const response = await wpClient.post(
        `sitio/v1/plan/order/capture/${paypalOrderID}`,
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data.message);
    }
  },
};
