import { wpService } from "@/services/wp";
import _includes from "lodash/includes";
import _merge from "lodash/merge";
import _assign from "lodash/assign";
import router from "@/router";
import { wpClient } from "@/services/wp/wp-client";
import { floor } from "lodash";

// ===
// Private helpers
// ===

function setAuthHeaders(data) {
  if (data) {
    wpClient.client.defaults.headers.common.Authentication =
      "Bearer " + data.jwt.token;
  } else {
    wpClient.client.defaults.headers.common.Authentication = "";
  }
  // console.log(token);
  // console.log("setAuthHeaders", wpClient);
}

// function getSavedState(key) {
//   return JSON.parse(window.localStorage.getItem(key))
// }

// function saveState(key, state) {
//   window.localStorage.setItem(key, JSON.stringify(state))
// }

let session = JSON.parse(localStorage.getItem("sitio_session"));
if (session) {
  // let now = new Date().getTime();
  // let expiration = session.jwt.token_expires * 1000;
  // let dif = expiration - now;
  // console.log("dif", dif);
  // console.log("now", now);
  // console.log("expiration", expiration);
  if (
    !{}.hasOwnProperty.call(session, "jwt") ||
    !{}.hasOwnProperty.call(session.jwt, "token_expires") ||
    session.jwt.token_expires * 1000 < new Date().getTime()
  ) {
    localStorage.removeItem("sitio_session");
    session = null;
  }
}

const initialState = session
  ? { status: { loggedIn: true }, data: session }
  : { status: { loggedIn: false }, data: null };

export default {
  namespaced: true,
  state: initialState,

  getters: {
    currentAccount(state) {
      return state.data ? state.data.current_account : null;
    },
    userCredits(state, getters) {
      return getters.currentAccount ? getters.currentAccount.user_credits : 0;
    },
    accUserInfo(state, getters) {
      return getters.currentAccount;
    },
    accOrgData(state, getters) {
      return getters.currentAccount ? getters.currentAccount.user_org : null;
    },
    org_member_role(state, getters) {
      const definitions = {
        OWNER: {
          title: "Propietario",
        },
      };
      if (
        getters.accOrgData &&
        getters.accOrgData.member_role &&
        {}.hasOwnProperty.call(definitions, getters.accOrgData.member_role)
      ) {
        return definitions[getters.accOrgData.member_role];
      }
      return {
        title: "NAN",
      };
    },
    org_member_can: (state, getters) => (capability, org_id) => {
      if (!getters.accOrgData || getters.accOrgData.org_id !== org_id)
        return false;
      return _includes(getters.accOrgData.member_can, capability);
    },
    partner(state) {
      return state.data ? state.data.partner : null;
    },
    sponsored(state, getters) {
      if (!getters.partner) return false;
      return {}.hasOwnProperty.call(getters.partner, "credits");
    },
    loggedIn(state) {
      return !!state.status.loggedIn;
    },
    loggingIn(state) {
      return !!state.status.loggingIn;
    },
  },

  actions: {
    async init({ state, dispatch, commit }) {
      window.addEventListener("storage", evt => {
        // When local storage changes, dump the list to
        // the console.
        if (evt.key !== "sitio_session") return;
        // console.log("storage", evt);
        if (state.data && !evt.newValue) {
          // The user has been loged out in other window/tab
          router.push({ name: "logout" });
          return;
        }
        if (evt.newValue) {
          if (!state.data) {
            // The user has loged in on another window/tab
          } else {
            // The session has been updated
          }
          let sitio_session = JSON.parse(evt.newValue);
          // console.log(sitio_session);
          commit("SET_CURRENT_DATA", sitio_session, false);
        }
        // console.log(JSON.parse(window.localStorage.getItem('sampleList')));
      });
      console.log("init: session", state);
      setAuthHeaders(state.data);
      if (state.data && !!state.data.jwt) {
        // console.log("get session");
        dispatch("validateAuthToken").then(() => {
          let expires = state.data.jwt.token_expires;
          let now = floor(new Date().getTime() / 1000);
          let dif = expires - now;
          console.log(`token expires in ${dif}`);
          /**
           *  If less than 1 HOUR
           */
          if (dif < 3600) {
            return dispatch("renewAuthToken");
          }
          // if (dif < 60) {
          return dispatch("refreshcurrentAccount");
          // }
        });
      }
    },

    validateAuthToken({ state }) {
      // let token = !!state.data.jwt ?
      // if (!state.data || !state.data.hasOwnProperty("jwt"))
      if (!!state.data.jwt.token == false) return Promise.reject(null);

      return wpService.session
        .validate(state.data.jwt.token)
        .then(data => {
          return data;
        })
        .catch(error => {
          router.replace({ name: "logout" });
          console.warn(error);
          return null;
        });
    },

    // TODO: Set interval token refresher
    renewAuthToken({ state, dispatch, commit }) {
      return wpService.session.refresh(state.data.jwt.token).then(response => {
        let update = _merge({}, state.data);
        update.jwt = response;
        commit("SET_CURRENT_DATA", update);
        setTimeout(() => {
          dispatch("refreshcurrentAccount");
        }, 3000);
      });
    },

    setUserData({ commit }, current_account = null) {
      commit("SET_CURRENT_DATA", { current_account });
    },

    async refreshcurrentAccount({ dispatch }) {
      // await wpService.account.getData().then(response => {
      //   dispatch("setUserData", response);
      // });
      try {
        const response = await wpService.account.getData();
        dispatch("setUserData", response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    login({ commit }, { username, password }) {
      commit("LOGGIN_IN");
      return wpService.session
        .login(username, password)
        .then(data => {
          commit("SET_CURRENT_DATA", data);
          // router.replace({ name: "account-listings" });
          return Promise.resolve(data);
        })
        .catch(err => {
          commit("SET_CURRENT_DATA", null);
          if (
            {}.hasOwnProperty.call(err, "errors") &&
            {}.hasOwnProperty.call(err.errors, "rest_jwt_auth_failure")
          ) {
            return Promise.reject(
              "Ingresó una combinación incorrecta de nombre de usuario y contraseña.",
            );
          }
          return Promise.reject(err);
        });
    },

    logout({ commit }) {
      commit("SET_CURRENT_DATA", null);
    },
  },

  mutations: {
    SET_CURRENT_DATA(state, data, updateLocalStorage = true) {
      if (data) {
        let current_data = _merge({}, state.data);
        let update = _merge({}, data);
        let new_data = _assign({}, current_data, update);
        if (updateLocalStorage) {
          localStorage.setItem("sitio_session", JSON.stringify(new_data));
        }
        if ({}.hasOwnProperty.call(update, "jwt")) {
          setAuthHeaders(new_data);
        }
        state.data = new_data;
        state.status = { loggedIn: true };
      } else {
        setAuthHeaders(null);
        if (updateLocalStorage) {
          localStorage.removeItem("sitio_session");
        }
        state.status = { loggedIn: false };
        setTimeout(() => {
          state.data = null;
        }, 100);
      }
    },
    LOGGIN_IN(state) {
      state.status = { loggingIn: true };
    },
  },
};
