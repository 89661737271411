import merge from "lodash/merge";
import { wpClient } from "../wp-client";

export default {
  async upload(filename = null, filetype = null, data = null, conf = {}) {
    let defaultConf = merge(
      {},
      {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Disposition": `attachment; filename=${filename}`,
          "Content-Type": filetype,
        },
      },
    );
    conf = merge({}, defaultConf, conf);
    try {
      const response = await wpClient.post("wp/v2/media/", data, conf);
      console.log("media->upload raw", response);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async delete(ID) {
    let config = {
      params: {
        force: true,
      },
    };
    try {
      const response = await wpClient.delete("wp/v2/media/" + ID, config);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
};
