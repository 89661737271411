import appConfig from "@/app.config";
import store from "@/stores";
import router from "@/router";
import { NewClient } from "../client";
import { sitioBus } from "@/main";
// import { hashJson } from "@/utils/helpers";

const wpClient = new NewClient(appConfig.wpRest);
wpClient.client.defaults.headers.common.Accept = "application/json";
wpClient.client.defaults.headers.common["Content-Type"] = "application/json";
wpClient.client.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
// console.log("test", wpClient);

// wpClient.client.interceptors.request.use(request => {
//   let baseURL = request.baseURL;
//   let url = baseURL + request.url.replace(baseURL, "");
//   let method = request.method;
//   let params = request.params;
//   // console.log(url);
//   if (method === "get" && url === baseURL + "sitio/v1/listings") {
//     // request.params.cache = true;
//     let cachename = "sitio_cache_get_listings_" + hashJson({ url, params });
//     // console.log("cachename", cachename);
//     let cached = JSON.parse(localStorage.getItem(cachename));
//     if (cached) {
//       if (!cached.data || !cached.expires || cached.expires < Date.now()) {
//         localStorage.removeItem(cachename);
//         console.log("Expired cached response found... delete and request");
//         return request;
//       }
//       // console.log(new Date(cached.expires), "Cached response expires");
//       // console.log(new Date(), "current date");
//       // console.log(cached);

//       // request.data = cached.data;
//       request.params.__cache = false;
//       request.adapter = () => {
//         return Promise.resolve({
//           data: cached.data,
//           status: request.status,
//           statusText: request.statusText,
//           headers: request.headers,
//           config: request,
//           request: request
//         });
//       };
//     }
//   }
//   return request;
// });

// Add a response interceptor
wpClient.client.interceptors.response.use(
  response => {
    // // if you dont want to cache a specific url, send a param `__cache = false`
    // const method = response.config.method;
    // const params = response.config.params || null;
    // const isCacheable = !params || (params && params.__cache !== false);
    // let baseURL = response.config.baseURL;
    // let url = baseURL + response.config.url.replace(baseURL, "");
    // if (
    //   isCacheable &&
    //   method === "get" &&
    //   url === baseURL + "sitio/v1/listings"
    // ) {
    //   let entries = response.data.entries;
    //   let cacheExpiry = Date.now() + 60 * 5 * 1000;
    //   for (let index = 0; index < entries.length; index++) {
    //     if (entries[index].ltg_display_end_date * 1000 < cacheExpiry) {
    //       cacheExpiry = entries[index].ltg_display_end_date * 1000;
    //     }
    //   }
    //   if (cacheExpiry < 30 * 1000) return response;
    //   let cacheData = {
    //     expires: cacheExpiry,
    //     data: response.data
    //   };
    //   let cachename = "sitio_cache_get_listings_" + hashJson({ url, params });
    //   localStorage.setItem(cachename, JSON.stringify(cacheData));
    // }

    if ({}.hasOwnProperty.call(response.data, "update_account_listing")) {
      sitioBus.$emit(
        "update_account_listing",
        response.data.update_account_listing,
      );
      console.log(
        "update_account_listing",
        response.data.update_account_listing,
      );
    }

    if ({}.hasOwnProperty.call(response.data, "update_current_account")) {
      store.dispatch(
        "session/setUserData",
        response.data.update_current_account,
      );
    }
    return response;
  },
  error => {
    if (error.response.status === 401) {
      console.log(error.response);
      router.push({ name: "logout" });
    } else if (error.response.status === 402) {
      console.error("PAYMENT REQUIRED");
    } else if (error.response.status === 403) {
      // console.log(error.response.data);
      if (error.response.data.code) {
        switch (error.response.data.code) {
          case "jwt_auth_invalid_token":
            break;
          case "jwt_auth_bad_iss":
            break;
          default:
            break;
        }
      }
    } else if (error.response.status >= 500) {
      // Raven.captureException(error);
    }

    return Promise.reject(error);
  },
);

export { wpClient };
