import { isMobile } from "@/utils/helpers";

import { use, registerComponentProgrammatic } from "@/utils/plugins";

const check = isMobile();

const Plugin = {
  install(Vue) {
    registerComponentProgrammatic(Vue, "isMobile", check);
  },
};

use(Plugin);

export default Plugin;
