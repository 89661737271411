import { wpClient } from "../wp-client";

export default {
  async login(username, password) {
    const body = {
      username,
      password,
      issueJWT: true,
      refreshableJWT: true,
    };
    try {
      const response = await wpClient.post("sitio/v1/auth/login", body);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response);
    }
  },
  async validate(token) {
    const body = { jwt: token };
    try {
      const response = await wpClient.post("sitio/v1/auth/validate", body);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async refresh(token) {
    const body = { jwt: token };
    try {
      const response = await wpClient.post("sitio/v1/auth/refresh", body);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async revoke(token) {
    const body = { jwt: token };
    try {
      const response = await wpClient.post("sitio/v1/auth/revoke", body);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
};
