import merge from "lodash/merge";
import { wpClient } from "../wp-client";

export default {
  async getOrgSlug(org_id = "", conf = {}) {
    conf = merge({}, conf);
    try {
      const response = await wpClient.get(
        `sitio/v1/organization-slug/${org_id}`,
        conf,
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async getOrgInfo(org_slug = "", conf = {}) {
    conf = merge({}, conf);
    try {
      const response = await wpClient.get(
        `sitio/v1/organizations/${org_slug}/info`,
        conf,
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async queryOrgListings(org_id = 0, conf = {}) {
    conf = merge({}, conf);
    try {
      const response = await wpClient.get(
        `sitio/v1/organizations/${org_id}/listings`,
        conf,
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async queryOrgMembers(org_id = 0, conf = {}) {
    conf = merge({}, conf);
    try {
      const response = await wpClient.get(
        `sitio/v1/organizations/${org_id}/members`,
        conf,
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async queryOrgOffices(org_id = 0, conf = {}) {
    conf = merge({}, conf);
    try {
      const response = await wpClient.get(
        `sitio/v1/organizations/${org_id}/offices`,
        conf,
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async orgInviteVerify(invite_id = 0) {
    try {
      const response = await wpClient.post(
        `sitio/v1/organization-invite/verify/`,
        { invite_id },
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },

  async postOrgAvatar(
    filename = null,
    filetype = null,
    file = null,
    conf = {},
  ) {
    let defaultConf = merge(
      {},
      {
        headers: {
          "Content-Disposition": `attachment; filename=${filename}`,
          "Content-Type": filetype,
          "Cache-Control": "no-cache",
        },
      },
    );
    conf = merge({}, defaultConf, conf);
    try {
      const response = await wpClient.post(
        "sitio/v1/organization/avatar/",
        file,
        conf,
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },

  // Confirm signup
  async deleteOrgAvatar() {
    try {
      const response = await wpClient.delete("sitio/v1/organization-avatar/");
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
};
