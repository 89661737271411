import settings from "./settings";
import config from "./config";
import session from "./session";
import singleListing from "./single-listing";

const modules = {
  settings,
  session,
  config,
  singleListing,
};

export default modules;
