<script>
import { mapGetters } from "vuex";
import { isPWA } from "@/utils/helpers";
import { version } from "../package.json";
import firebase from "firebase/app";
import { Wormhole } from "portal-vue";

export default {
  name: "app",

  metaInfo: {
    changed(metaInfo) {
      firebase.analytics().setCurrentScreen(metaInfo.title);
      firebase.analytics().logEvent("page_view");
      firebase.analytics().logEvent("screen_view", {
        app_name: isPWA() ? "pwa" : "web",
        screen_name: metaInfo.title,
        app_version: version,
      });
    },
    titleTemplate: titleChunk =>
      titleChunk
        ? `${titleChunk} - Sitio`
        : "Sitio - Venta y alquiler de propiedades en República Dominicana.",
  },

  data() {
    return {
      loading: null,
      uiFull: true,
      scrollOffset: 60,
      lastScrollPosition: 0,
    };
  },

  computed: {
    ...mapGetters("config", ["isConfig"]),
    appClasses() {
      let classes = [];
      if (!this.uiFull) classes.push("simplify");
      // if (this.$route.name) classes.push("route--" + this.$route.name);
      return classes;
    },
    anyModal() {
      return Wormhole.hasContentFor("modal");
    },
  },

  watch: {
    isConfig(value) {
      this.loading.isActive = !value;
    },
    $route() {
      this.updateBodyName();
    },
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },

  mounted() {
    console.log("app: mounted");
    window.addEventListener("scroll", this.onScroll);
  },

  created() {
    console.log("app: created");
    /**
     * 1. If we have a token attach it to requests
     * 1. If no config, fetch
     * 2. If config too old, fetch
     * 3. If logedIn, validate
     */
    if (!this.isConfig) {
      this.loading = this.$buefy.loading.open({ container: null });
    }
    this.updateBodyName();
    // if (this.$workbox) {
    //   this.$workbox.addEventListener("waiting", event => {
    //     console.log("App.vue workbox evt: waiting", event.isUpdate);
    //   });
    //   this.$workbox.addEventListener("installed", event => {
    //     console.log("App.vue workbox evt: installed", event.isUpdate);
    //     if (event.isUpdate) {
    //       this.$buefy.dialog.alert({
    //         message: `New content is available!. Click OK to refresh`,
    //         onConfirm: () => {
    //           this.$buefy.toast.open("Reloading");
    //           window.location.reload();
    //         },
    //       });
    //     }
    //   });
    //   this.$workbox.addEventListener("controlling", event => {
    //     console.log("App.vue workbox evt: controlling", event.isUpdate);
    //   });
    // }
  },

  methods: {
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return;
      }
      if (
        Math.abs(window.pageYOffset - this.lastScrollPosition) <
        this.scrollOffset
      ) {
        return;
      }
      // Here we determine whether we need to show or hide the navbar
      this.uiFull = currentScrollPosition < this.lastScrollPosition;
      // Set the current scroll position as the last scroll position
      this.lastScrollPosition = currentScrollPosition;
    },
    updateBodyName() {
      // console.log(document.documentElement);
      // document.body.setAttribute("route", this.$route.name);
      document.documentElement.setAttribute("route", this.$route.name);
    },
  },
};
</script>

<template>
  <div class="appContainer">
    <router-view class="sitioApp" v-if="isConfig" :class="appClasses" />
    <portal-target name="mudal"></portal-target>
    <portal-target name="listing" slim></portal-target>
    <portal-target
      v-if="anyModal"
      name="modal"
      class="modal_portal"
    ></portal-target>
    <div id="rotatePrompt">
      <div>
        <img src="@/assets/rotatedevice.svg" />
        <p>Por favor gire su dispositivo</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import "~nprogress/nprogress.css";

// Imported globally in vue.config.js
// Bulma's core
// @import "~bulma/sass/utilities/_all";
// Custom Variables
// @import "styles/_variables.scss"; // Set your colors

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

// Import Multiselect styles
@import "~vue-multiselect/dist/vue-multiselect.min.css";

@import "@/styles/_global.scss";
@import "@/styles/bugfix.scss";

// $primary: #7957d5;
</style>
