<script>
import trapFocus from "@/directives/trapFocus";
import config from "@/utils/config";
import { removeElement } from "@/utils/helpers";
import svgClose from "@/svg/close";

export default {
  name: "SModal",

  directives: {
    trapFocus,
  },

  components: {
    svgClose,
  },

  props: {
    active: Boolean,
    component: [Object, Function],
    content: String,
    programmatic: Boolean,
    props: Object,
    events: Object,
    width: {
      type: [String, Number],
      default: 960,
    },
    hasModalCard: Boolean,
    animation: {
      type: String,
      default: "zoom-out",
    },
    canCancel: {
      type: [Array, Boolean],
      default: () => {
        return config.defaultModalCanCancel;
      },
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
    scroll: {
      type: String,
      default: "clip",
      validator: value => {
        return ["clip", "keep"].indexOf(value) >= 0;
      },
    },
    fullScreen: Boolean,
    trapFocus: {
      type: Boolean,
      default: config.defaultTrapFocus,
    },
    customClass: String,
  },

  data() {
    return {
      isActive: this.active || false,
      savedScrollTop: null,
      newWidth: typeof this.width === "number" ? this.width + "px" : this.width,
      animating: true,
    };
  },

  computed: {
    cancelOptions() {
      return typeof this.canCancel === "boolean"
        ? this.canCancel
          ? config.defaultModalCanCancel
          : []
        : this.canCancel;
    },
    showX() {
      return this.cancelOptions.indexOf("x") >= 0;
    },
    customStyle() {
      if (!this.fullScreen) {
        return { maxWidth: this.newWidth };
      }
      return null;
    },
  },

  watch: {
    active(value) {
      this.isActive = value;
    },
    isActive(value) {
      this.handleScroll();
      this.$nextTick(() => {
        if (value && this.$el && this.$el.focus) {
          this.$el.focus();
        }
      });
    },
  },

  methods: {
    handleScroll() {
      if (typeof window === "undefined") return;

      if (this.scroll === "clip") {
        if (this.isActive) {
          document.documentElement.classList.add("is-clipped");
        } else {
          document.documentElement.classList.remove("is-clipped");
        }
        return;
      }

      this.savedScrollTop = !this.savedScrollTop
        ? document.documentElement.scrollTop
        : this.savedScrollTop;

      if (this.isActive) {
        document.body.classList.add("is-noscroll");
      } else {
        document.body.classList.remove("is-noscroll");
      }

      if (this.isActive) {
        document.body.style.top = `-${this.savedScrollTop}px`;
        return;
      }

      document.documentElement.scrollTop = this.savedScrollTop;
      document.body.style.top = null;
      this.savedScrollTop = null;
    },

    /**
     * Close the Modal if canCancel and call the onCancel prop (function).
     */
    cancel(method) {
      if (this.cancelOptions.indexOf(method) < 0) return;

      this.onCancel.apply(null, arguments);
      this.close();
    },

    /**
     * Emit events, and destroy component if it's programmatic.
     */
    close() {
      this.$emit("close");
      this.$emit("update:active", false);
      // Timeout for the animation complete before destroying
      if (this.programmatic) {
        this.isActive = false;
        setTimeout(() => {
          this.$destroy();
          removeElement(this.$el);
        }, 150);
      }
    },

    /**
     * Keypress event that is bound to the document.
     */
    keyPress(event) {
      // Esc key
      if (this.isActive && event.keyCode === 27) this.close();
    },

    /**
     * Transition after-enter hook
     */
    afterEnter() {
      this.animating = false;
    },

    /**
     * Transition before-leave hook
     */
    beforeLeave() {
      this.animating = true;
    },
  },

  created() {
    if (typeof window !== "undefined") {
      document.addEventListener("keyup", this.keyPress);
    }
  },

  beforeMount() {
    // Insert the Auth comp in body tag
    // only if it's programmatic
    this.programmatic && document.body.appendChild(this.$el);
  },

  mounted() {
    if (this.programmatic) this.isActive = true;
    else if (this.isActive) this.handleScroll();
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      document.removeEventListener("keyup", this.keyPress);
      // reset scroll
      document.documentElement.classList.remove("is-clipped");
      const savedScrollTop = !this.savedScrollTop
        ? document.documentElement.scrollTop
        : this.savedScrollTop;
      document.body.classList.remove("is-noscroll");
      document.documentElement.scrollTop = savedScrollTop;
      document.body.style.top = null;
    }
  },
};
</script>

<template>
  <transition
    :name="animation"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
  >
    <div
      v-if="isActive"
      class="sitioModal is-active disable_touch_callout"
      :class="[{ 'is-full-screen': fullScreen }, customClass]"
      v-trap-focus="trapFocus"
    >
      <div class="sitioModal__background" @click="cancel('outside')" />
      <div
        class="sitioModal__body"
        :class="{ 'modal-content': !hasModalCard }"
        :style="customStyle"
      >
        <component
          class="sitioModal__content"
          v-if="component"
          v-bind="props"
          v-on="events"
          :is="component"
          @close="close"
        />
        <div v-else-if="content" v-html="content" class="sitioModal__content" />
        <slot v-else class="sitioModal__content" />
        <button
          type="button"
          v-if="showX && !animating"
          class="sitioModal__close"
          @click="cancel('x')"
        />
      </div>
    </div>
  </transition>
</template>
