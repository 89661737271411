<template>
  <transition :name="animation">
    <div
      class="loading-overlay is-active"
      :class="{ 'is-full-page': isFullPage }"
      v-if="isActive"
    >
      <div class="loading-background" @click="cancel" />
      <div class="loading-container">
        <slot>
          <div class="loading-image" />
          <div class="loading-message" v-if="message">{{ message }}</div>
        </slot>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.loading-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
}

.loading-overlay.is-active {
  display: flex;
}

.loading-overlay.is-full-page {
  z-index: 999;
  position: fixed;
}

.loading-overlay .loading-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #7f7f7f;
  background: rgba(255, 255, 255, 0.95);
}

.loading-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  > :not(:last-child) {
    margin-bottom: 1rem;
  }
}

.loading-overlay /deep/ {
  .loading-image,
  .loading-image:after {
    width: 3em;
    height: 3em;
  }
  .loading-image {
    position: relative;
  }
  .loading-image:after {
    animation: spinAround 500ms infinite linear;
    // border: 2px solid #dbdbdb;
    border: 2px solid $sitio2;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    border-width: 0.25em;
    content: "";
    display: block;
    position: absolute;
  }
}

// .loading-overlay /deep/ .loading-image:after {
//   top: calc(50% - 1.5em);
//   left: calc(50% - 1.5em);
// }

// .loading-overlay.is-full-page /deep/ .loading-image:after {
//   top: calc(50% - 2.5em);
//   left: calc(50% - 2.5em);
//   width: 5em;
//   height: 5em;
// }
</style>

<script>
import { removeElement } from "@/utils/helpers";
import { HTMLElement } from "@/utils/ssr";

export default {
  name: "SLoading",
  props: {
    message: String,
    active: Boolean,
    programmatic: Boolean,
    container: [Object, Function, HTMLElement],
    isFullPage: {
      type: Boolean,
      default: true,
    },
    animation: {
      type: String,
      default: "fade",
    },
    canCancel: {
      type: Boolean,
      default: false,
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isActive: this.active || false,
    };
  },
  watch: {
    active(value) {
      this.isActive = value;
    },
  },
  methods: {
    /**
     * Close the Modal if canCancel.
     */
    cancel() {
      if (!this.canCancel || !this.isActive) return;

      this.close();
    },
    /**
     * Emit events, and destroy modal if it's programmatic.
     */
    close() {
      this.onCancel.apply(null, arguments);
      this.$emit("close");
      this.$emit("update:active", false);

      // Timeout for the animation complete before destroying
      if (this.programmatic) {
        this.isActive = false;
        setTimeout(() => {
          this.$destroy();
          removeElement(this.$el);
        }, 150);
      }
    },
    /**
     * Keypress event that is bound to the document.
     */
    keyPress(event) {
      // Esc key
      if (event.keyCode === 27) this.cancel();
    },
  },
  created() {
    if (typeof window !== "undefined") {
      document.addEventListener("keyup", this.keyPress);
    }
  },
  beforeMount() {
    // Insert the Loading component in body tag
    // only if it's programmatic
    if (this.programmatic) {
      if (!this.container) {
        document.body.appendChild(this.$el);
      } else {
        this.isFullPage = false;
        this.container.appendChild(this.$el);
      }
    }
  },
  mounted() {
    if (this.programmatic) this.isActive = true;
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      document.removeEventListener("keyup", this.keyPress);
    }
  },
};
</script>
