var wpRest;
wpRest = "https://api.sitio.do/wp-json/";
wpRest = "https://mt1.sitio.do/wp-json/"; /* MAMP */

if (process.env.NODE_ENV !== "production") {
  wpRest = "https://api.sitio.test:8890/wp-json/"; /* MAMP */
  // wpRest = "https://mt1.sitio.do/wp-json/"; /* MAMP */
  // wpRest = "https://api.sitio.test/wp-json/"; /* DOCKER */
  // wpRest = "https://local.sitio.do/wp-json/"; /* DOCKER */
}

let isPayPalSandbox = true;
let paypalSandboxID =
  "AYDivykXIlz5yXWbk7Ky1H1xP-h5dIDz_fk_Mb3sXD5jaiwf9i8ULeop40cZwxoEFx7gk7ySNGrRw0xf";
let paypalProductionID =
  "AfILDZIKysSJY5lhMOfpYfhNlPkmhpJHVR3PGhU2KccI9OB9cgsiNwTmnuJjK5YICax4kEI_bC-fQoPF";

const config = {
  paypalID: isPayPalSandbox ? paypalSandboxID : paypalProductionID,
  wpRest,

  jwt_login: "sitio/v1/auth/login",
  jwt_validate: "sitio/v1/auth/validate",
  jwt_refresh: "sitio/v1/auth/refresh",
  jwt_revoke: "sitio/v1/auth/revoke",

  signupRequest: "sitio/v1/signup",
  signupVerify: "sitio/v1/signup/verify",
  signupComplete: "sitio/v1/signup/complete",

  recoveryRequest: "sitio/v1/recovery",
  recoveryVerify: "sitio/v1/recovery/verify",
  recoveryComplete: "sitio/v1/recovery/complete",

  accountPatch: "sitio/v1/account",

  passwordChange: "sitio/v1/password" /** PATCH */,
  emailChange: "sitio/v1/email" /** PATCH */,
};

export default config;
