let config = {
  defaultModalCanCancel: ["escape", "x", "outside", "button"],
  defaultTrapFocus: true,
};

export default config;

export const setOptions = options => {
  config = options;
};

export const setVueInstance = Vue => {
  VueInstance = Vue;
};

export let VueInstance;
