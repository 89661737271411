import appConfig from "@/app.config";

/**
 * This function defines if app is installed on homescreen as PWA
 */
export function isPWA() {
  return window && window.matchMedia("(display-mode: standalone)").matches;
}

export function hashJson(json = {}, asString = true) {
  return hashString(JSON.stringify(json), asString);
}

export function hashString(str = "", asString = true) {
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  if (asString) {
    // Convert to 8 digit hex string
    return ("0000000" + (hash >>> 0).toString(16)).substr(-8);
  }
  return hash;
}

export function authHeader() {
  let headers = {
    /* My invention to mood remove wordpress_logged_in cookie via apache */
    // "X-Authenticate-With": "JWT",
    "x-requested-with": "XMLHttpRequest",
    "content-type": "application/json",
    accept: "application/json",
  };
  // return authorization header with token token
  let session = JSON.parse(localStorage.getItem("sitio_session"));

  if (session && session.token) {
    // headers["Authorization"] = "Bearer " + session.token;
    // AAM WP Plugin uses a custom header to send the Bearer
    headers["authentication"] = "Bearer " + session.token;
  }

  return headers;
}

export async function paypalSetup() {
  let paypalSDKsrc =
    "https://www.paypal.com/sdk/js?client-id=" + appConfig.paypalID;
  // console.log(paypalSDKsrc);
  let paypalScript = document.querySelectorAll("script#paypal-sdk")[0];
  if (!paypalScript) {
    await new Promise(resolve => {
      paypalScript = document.createElement("script");
      paypalScript.type = "text/javascript";
      paypalScript.onload = function() {
        console.log("paypalScript load success");
        resolve();
      };
      paypalScript.onerror = function() {
        console.log("paypalScript load failure");
        resolve();
      };
      paypalScript.setAttribute("id", "paypal-sdk");
      paypalScript.src = paypalSDKsrc;
      // var head = document.getElementsByTagName("head")[0];
      // head.appendChild(paypalScript);
      document.body.appendChild(paypalScript);
    });
  }
  if (typeof paypal === "undefined") {
    console.log("paypal undefined");
    return Promise.resolve(false);
  } else {
    // eslint-disable-next-line no-undef
    console.log("paypal defined");
    // eslint-disable-next-line no-undef
    return Promise.resolve(paypal);
  }
}

/**
 * Merge function to replace Object.assign with deep merging possibility
 */
const isObject = item => typeof item === "object" && !Array.isArray(item);
const mergeFn = (target, source, deep = false) => {
  if (deep || !Object.assign) {
    const isDeep = prop =>
      isObject(source[prop]) &&
      {}.hasOwnProperty.call(target, prop) &&
      isObject(target[prop]);
    const replaced = Object.getOwnPropertyNames(source)
      .map(prop => ({
        [prop]: isDeep(prop)
          ? mergeFn(target[prop], source[prop])
          : source[prop],
      }))
      .reduce((a, b) => ({ ...a, ...b }), {});

    return {
      ...target,
      ...replaced,
    };
  } else {
    return Object.assign(target, source);
  }
};
export const merge = mergeFn;

export function removeElement(el) {
  if (typeof el.remove !== "undefined") {
    el.remove();
  } else if (typeof el.parentNode !== "undefined") {
    el.parentNode.removeChild(el);
  }
}

export function dateUnixLiteral(unix) {
  let date = new Date(unix * 1000);
  let monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  let day = date.getDate();
  let monthIndex = date.getMonth();
  let year = date.getFullYear();
  return `${monthNames[monthIndex]} ${day}, ${year}`;
}

/**
 * Formats a phone number.
 * Example: 123-456-7890 => (123) 456-7890
 *
 * @param {String} phone
 * @return {Void}
 */
export function formatPhone(phone) {
  return phone
    .replace(/[^0-9]/g, "")
    .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
}

export function formatMoney(n, c, d, t) {
  c = isNaN((c = Math.abs(c))) ? 2 : c;
  d = d == undefined ? "." : d;
  t = t == undefined ? "," : t;
  var s = n < 0 ? "-" : "";
  var i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))));
  var j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}

export function stringSplit(string, sep) {
  const a = string.split(sep);
  if (a[0] === "" && a.length === 1) return [];
  return a;
}

/**
 * Decompose a string
 *
 * .normalize("NFD")                  split complex characters like diacritical marks (eg: á, ü, ñ...)
 *
 * .replace(/[\u0300-\u036f]/g, "")   replace by regexing any characters matching Unicode points from
 *                                    U+0300 to U+036F, covering any diacritic bytes the string may contain
 *
 * .toLowerCase()                     transforms string to lower case
 *
 * https://thread.engineering/2018-08-29-searching-and-sorting-text-with-diacritical-marks-in-javascript/
 * @param {string} string
 */
export function stringDecompose(string) {
  return string
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
}

export function isMobile() {
  if (typeof window === "undefined") return false;
  if (window.navigator.userAgent.match(/Android/i)) return "Android";
  if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) return "iOS";
  if (window.navigator.userAgent.match(/BlackBerry/i)) return "BlackBerry";
  if (window.navigator.userAgent.match(/Opera Mini/i)) return "Opera";
  if (window.navigator.userAgent.match(/IEMobile/i)) return "Windows";
  return false;
}

/**
 * Mobile detection
 * https://www.abeautifulsite.net/detecting-mobile-devices-with-javascript
 *
 * Example 1: if( isMobile.any() ) alert('Mobile');
 * Example 2: if( isMobile.iOS() ) alert('iOS');
 */
export const _isMobile = {
  Android: function() {
    return (
      typeof window !== "undefined" &&
      window.navigator.userAgent.match(/Android/i)
    );
  },
  iOS: function() {
    return (
      typeof window !== "undefined" &&
      window.navigator.userAgent.match(/iPhone|iPad|iPod/i)
    );
  },
  BlackBerry: function() {
    return (
      typeof window !== "undefined" &&
      window.navigator.userAgent.match(/BlackBerry/i)
    );
  },
  Opera: function() {
    return (
      typeof window !== "undefined" &&
      window.navigator.userAgent.match(/Opera Mini/i)
    );
  },
  Windows: function() {
    return (
      typeof window !== "undefined" &&
      window.navigator.userAgent.match(/IEMobile/i)
    );
  },
  any: function() {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};
