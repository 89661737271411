import Auth from "./Auth";

import { VueInstance } from "@/utils/config";
import {
  use,
  registerComponent,
  registerComponentProgrammatic,
} from "@/utils/plugins";

let localVueInstance;

const AuthProgrammatic = {
  open(params) {
    const defaultParam = {
      programmatic: true,
    };
    let parent;
    if (params.parent) {
      parent = params.parent;
      delete params.parent;
    }
    const propsData = Object.assign(defaultParam, params);

    const vm =
      typeof window !== "undefined" && window.Vue
        ? window.Vue
        : localVueInstance || VueInstance;
    const AuthComponent = vm.extend(Auth);
    return new AuthComponent({
      parent,
      el: document.createElement("div"),
      propsData,
    });
  },
};

const Plugin = {
  install(Vue) {
    localVueInstance = Vue;
    registerComponent(Vue, Auth);
    registerComponentProgrammatic(Vue, "auth", AuthProgrammatic);
  },
};

use(Plugin);

export default Plugin;

export { AuthProgrammatic };
