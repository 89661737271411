import _merge from "lodash/merge";

export default ({ routeTo, next }) => {
  let ltg_id = Number(routeTo.query.ltg_id) || 0;
  if (!ltg_id) {
    return next({
      name: "finder",
      replace: true,
    });
  }
  let query = _merge({}, routeTo.query);
  delete query.init;
  delete query.ltg_id;
  next({
    name: "finder-single",
    params: { ltg_id },
    query: query,
    replace: true,
  });
};
