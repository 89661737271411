// import _find from "lodash/find";
// import _filter from "lodash/filter";
import { wpService } from "@/services/wp";

export default {
  namespaced: true,

  state: {
    obsolete: false,
  },

  getters: {
    is_obsolete(state) {
      return state.obsolete;
    },
  },

  actions: {
    obsolete({ commit }) {
      commit("set_obsolete", true);
    },
    async fetch({ commit }, ltg_id = 0) {
      commit("set_obsolete", false);
      try {
        const response = await wpService.listings.getSingle(ltg_id);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },

  mutations: {
    set_obsolete(state, value) {
      state.obsolete = value;
    },
  },
};
