import Vue from "vue";
import store from "@/stores";
import Router from "vue-router";
import NProgress from "nprogress/nprogress";
import middlewarePipeline from "./middlewarePipeline";
import openListing from "./middleware/open-listing";
import VueRouterBackButton from "vue-router-back-button";
import { wpService } from "@/services/wp";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "*",
      component: () => import(/* webpackChunkName: "404" */ "@/views/404.vue"),
    },
    {
      path: "/404",
      name: "404",
      component: () => import(/* webpackChunkName: "404" */ "@/views/404.vue"),
    },
    {
      name: "home",
      path: "/",
      redirect: { name: "finder" },
      component: () =>
        import(/* webpackChunkName: "inicio" */ "@/views/inicio.vue"),
    },
    {
      name: "logout",
      path: "/logout",
      beforeEnter(routeTo, routeFrom, next) {
        if (!{}.hasOwnProperty.call(routeTo.query, "next")) {
          // Navigate back to previous page, or home as a fallback
          const onlyUsersOnPreviousRoute = routeFrom.matched.some(
            route => route.meta.onlyUsers,
          );
          const route = {
            name: "logout",
            // replace: true,
            query: {
              next: onlyUsersOnPreviousRoute ? "/" : routeFrom.fullPath,
            },
          };
          return next(route);
        }
        return next();
      },
      component: () =>
        import(/* webpackChunkName: "logout" */ "@/views/logout.vue"),
    },
    {
      name: "acc-watching",
      path: "/acc/watching",
      meta: { onlyUsers: true },
      component: () =>
        import(
          /* webpackChunkName: "acc-watching" */ "@/views/acc-watching.vue"
        ),
    },
    {
      name: "acc-inbox",
      path: "/acc/inbox",
      meta: { onlyUsers: true },
      component: () =>
        import(/* webpackChunkName: "acc-inbox" */ "@/views/acc-inbox.vue"),
    },
    {
      name: "acc-org",
      path: "/acc/org",
      meta: { onlyUsers: true },
      component: () =>
        import(/* webpackChunkName: "acc-org" */ "@/views/acc-org.vue"),
    },
    {
      name: "acc-listings",
      path: "/acc/listings",
      meta: { onlyUsers: true },
      component: () =>
        import(
          /* webpackChunkName: "acc-listings" */ "@/views/acc-listings.vue"
        ),
      children: [
        {
          name: "acc-listings-single",
          path: ":ltg_id(\\d+)/",
        },
      ],
    },
    {
      path: "/account",
      name: "account",
      redirect: { name: "account-listings" },
      meta: { onlyUsers: true },
      component: () =>
        import(/* webpackChunkName: "account" */ "@/views/account.vue"),
      children: [
        {
          name: "account-listings",
          path: "listings/",
          component: () =>
            import(
              /* webpackChunkName: "account-listings" */ "@/views/account-listings.vue"
            ),
          children: [
            {
              name: "account-listings-single",
              path: ":ltg_id(\\d+)/",
            },
          ],
        },
        {
          name: "account-credits",
          path: "credits/",
          component: () =>
            import(
              /* webpackChunkName: "account-credits" */ "@/views/account-credits.vue"
            ),
        },
        {
          name: "account-org",
          path: "org/",
          component: () =>
            import(
              /* webpackChunkName: "account-org" */ "@/views/account-org.vue"
            ),
        },
        {
          name: "account-watching",
          path: "watching/",
          component: () =>
            import(
              /* webpackChunkName: "account-watching" */ "@/views/account-watching.vue"
            ),
        },
        {
          name: "account-inbox",
          path: "inbox/",
          component: () =>
            import(
              /* webpackChunkName: "account-inbox" */ "@/views/account-inbox.vue"
            ),
        },
        {
          name: "account-profile",
          path: "profile/",
          component: () =>
            import(
              /* webpackChunkName: "account-profile" */ "@/views/account-profile.vue"
            ),
        },
        {
          name: "account-configuration",
          path: "configuration/",
          component: () =>
            import(
              /* webpackChunkName: "account-configuration" */ "@/views/account-configuration.vue"
            ),
        },
      ],
    },
    // {
    //   path: "/users",
    //   name: "query-users"
    // },
    {
      path: "/users/:user_id(\\d+)",
      name: "user",
      component: () =>
        import(/* webpackChunkName: "user" */ "@/views/user.vue"),
      children: [
        {
          path: "listings/:ltg_id(\\d+)",
          name: "user-listings-single",
        },
      ],
    },
    {
      path: "/orgs/:org_slug",
      name: "organization-single",
      redirect: { name: "organization-listings" },
      component: () =>
        import(
          /* webpackChunkName: "organization" */ "@/views/organization.vue"
        ),
      children: [
        {
          path: "members",
          name: "organization-members",
        },
        {
          path: "listings",
          name: "organization-listings",
        },
        {
          path: "listings/:ltg_id(\\d+)",
          name: "organization-listings-single",
        },
        {
          path: "offices",
          name: "organization-offices",
        },
      ],
    },
    {
      path: "/propiedades",
      name: "finder",
      component: () =>
        import(/* webpackChunkName: "finder" */ "@/views/finder.vue"),
      children: [
        {
          name: "finder-single",
          path: ":ltg_id(\\d+)",
        },
        {
          name: "finder-filter-trade",
          path: "filter-trade",
        },
        {
          name: "finder-filter-locations",
          path: "filter-locations",
        },
        {
          name: "finder-filter-generals",
          path: "filter-generals",
        },
        {
          name: "finder-filter-order",
          path: "filter-order",
        },
      ],
    },
    {
      path: "/acerca",
      name: "acerca",
      component: () =>
        import(/* webpackChunkName: "acerca" */ "@/views/acerca.vue"),
    },
    {
      path: "/contacto",
      name: "contacto",
      component: () =>
        import(/* webpackChunkName: "contacto" */ "@/views/contacto.vue"),
    },
    {
      path: "/password-recovery",
      name: "password-recovery",
      component: () =>
        import(
          /* webpackChunkName: "password-recovery" */ "@/views/password-recovery.vue"
        ),
    },
    {
      path: "/reauth",
      name: "reauth",
      component: () =>
        import(/* webpackChunkName: "reauth" */ "@/views/reauth.vue"),
      meta: {
        onlyGuests: true,
      },
    },
    {
      path: "/ex",
      name: "execute",
    },
    {
      path: "/gift",
      name: "gift-single",
      meta: {
        onlyUsers: true,
      },
      component: () =>
        import(/* webpackChunkName: "gift-single" */ "@/views/gift-single.vue"),
    },
    {
      path: "/org-invite/:invite_id(\\d+)",
      name: "org-invite",
      meta: {
        onlyUsers: true,
      },
      component: () =>
        import(/* webpackChunkName: "org-invite" */ "@/views/org-invite.vue"),
    },
  ],
});

Vue.use(VueRouterBackButton, { router });

router.beforeEach(async (routeTo, routeFrom, next) => {
  // console.log("ROUTER: beforeEach");
  // console.log("ROUTER: beforeEach", routeTo, routeFrom);

  // If this isn't an initial page load...
  if (routeFrom.name !== null) {
    // Start the route progress bar.
    NProgress.start();
  }

  const context = { routeTo, routeFrom, next, router, store };

  if ({}.hasOwnProperty.call(routeTo.query, "init")) {
    let init = routeTo.query.init;
    let query = routeTo.query;
    delete query.init;
    switch (init) {
      case "auth":
        return next({
          replace: true,
          name: "reauth",
          query,
        });
      case "open-gift":
        return next({
          name: "gift-single",
          query: query,
          replace: true,
        });
      case "open-user-profile":
        if (query.user_id) {
          return next({
            name: "user",
            params: { user_id: query.user_id },
            replace: true,
          });
        }
        break;
      case "open-org-invite":
        return next({
          name: "org-invite",
          params: { invite_id: query.id },
          replace: true,
        });
      case "open-org-profile":
        if (query.org_id) {
          const loading = router.app.$buefy.loading.open();
          try {
            const org_slug = await wpService.organizations.getOrgSlug(
              query.org_id,
            );
            loading.close();
            return next({
              name: "organization-single",
              params: { org_slug },
              replace: true,
            });
          } catch (err) {
            loading.close();
            console.log(err);
          }
        } else if (query.org_slug) {
          return next({
            name: "organization-single",
            params: { org_slug: query.org_slug },
            replace: true,
          });
        }
        break;
      case "open-listing":
        return openListing(context);
    }
    console.log("nothing to execute... go home");
    return next({
      name: "home",
      replace: true,
    });
  }

  // Users only routes
  // (including nested routes).
  const onlyUsers = routeTo.matched.some(route => route.meta.onlyUsers);
  if (onlyUsers && !store.getters["session/loggedIn"]) {
    return next({
      name: "reauth",
      replace: true,
      query: {
        next: routeTo.fullPath,
      },
    });
  }

  // Guests only routes
  // (including nested routes).
  const onlyGuests = routeTo.matched.some(route => route.meta.onlyGuests);
  if (onlyGuests && store.getters["session/loggedIn"]) {
    if (routeTo.query.next) {
      return next({ path: routeTo.query.next, replace: true });
    } else {
      // return next({ name: "account", replace: true });
      return next({ name: "home", replace: true });
    }
  }

  if (routeTo.meta.middleware) {
    const mwGuards = routeTo.meta.middleware;
    return mwGuards[0]({
      ...context,
      next: middlewarePipeline(context, mwGuards, 1),
    });
  }

  return next();
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // console.log("ROUTER: beforeResolve");
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (routeFrom.name === args[0].name) {
                // Complete the animation of the route progress bar.
                NProgress.done();
              }
              // Complete the redirect.
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }

  // If we reach this point, continue resolving the route.
  next();
});

// When each route is finished evaluating...
router.afterEach(() => {
  // console.log("ROUTER: afterEach");
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
