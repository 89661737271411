<script>
export default {
  name: "app-loading",
  props: {
    grow: {
      default: false,
      type: Boolean,
    },
    growX: {
      default: true,
      type: Boolean,
    },
    growY: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    classes() {
      if (this.grow || (this.growX && this.growY)) {
        return "uiLoading-grow";
      } else if (this.growX) {
        return "uiLoading-grow-x";
      } else if (this.growY) {
        return "uiLoading-grow-y";
      } else {
        return "";
      }
    },
  },
};
</script>

<template>
  <div class="app-loading uiLoading" :class="classes">
    <div class="uiLoadingDot dot-1"></div>
    <div class="uiLoadingDot dot-2"></div>
    <div class="uiLoadingDot dot-3"></div>
  </div>
</template>

<style lang="scss">
/* LOADER */
@media only screen {
  @keyframes loading {
    0% {
      transform: translateY(0);
    }
    25% {
      background-color: #cbd6e2;
      transform: translateY(0.25em);
    }
    50% {
      transform: translateY(0);
    }
    75% {
      background-color: #cbd6e2;
      transform: translateY(-0.25em);
    }
    to {
      transform: translateY(0);
    }
  }
  .loaded > .uiLoading {
    display: none;
  }
  .uiLoading {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 1.25em;
    min-height: 4rem;
    width: 4.5em;
  }
  .uiLoading.uiLoading-grow-x {
    width: 100%;
  }
  .uiLoading.uiLoading-grow-y {
    height: 100%;
  }
  .uiLoading.uiLoading-grow {
    height: 100%;
    width: 100%;
  }
  .uiLoading .uiLoadingDot {
    animation: loading 1s infinite linear;
    background-color: currentColor;
    border-radius: 50%;
    color: #7c98b6;
    height: 0.875em;
    width: 0.875em;
  }
  .uiLoading .uiLoadingDot.dot-1 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    margin-right: 0.625em;
  }
  .uiLoading .uiLoadingDot.dot-2 {
    -webkit-animation-delay: -0.66s;
    animation-delay: -0.66s;
    margin-right: 0.625em;
  }
  .uiLoading .uiLoadingDot.dot-3 {
    -webkit-animation-delay: -0.33s;
    animation-delay: -0.33s;
  }
  .uiLoading .uiLoadingDot.loading-blue {
    color: #00a4bd;
  }
  .uiLoading .uiLoadingDot.loading-orange {
    color: #e66e50;
  }
}
</style>
