import merge from "lodash/merge";
import { wpClient } from "../wp-client";

export default {
  async getUserInfo(user_id = 0, conf = {}) {
    conf = merge({}, conf);
    try {
      const response = await wpClient.get(
        `sitio/v1/users/${user_id}/info`,
        conf,
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async queryUserListings(user_id = 0, conf = {}) {
    conf = merge({}, conf);
    try {
      const response = await wpClient.get(
        `sitio/v1/users/${user_id}/listings`,
        conf,
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
};
