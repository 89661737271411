import _merge from "lodash/merge";
import { wpClient } from "../wp-client";

export default {
  async query(conf = {}) {
    conf = _merge({}, conf);
    try {
      const response = await wpClient.get("sitio/v1/listings/", conf);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async postSingle(data = null, conf = {}) {
    conf = _merge({}, conf);
    try {
      const response = await wpClient.post("sitio/v1/listings/", data, conf);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async getSingle(ltg_id = 0, conf = {}) {
    conf = _merge({}, conf);
    try {
      const response = await wpClient.get("sitio/v1/listings/" + ltg_id, conf);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async deleteSingle(listind_id = 0, conf = {}) {
    conf = _merge({}, conf);
    try {
      const response = await wpClient.delete(
        "sitio/v1/listings/" + listind_id,
        conf,
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async patchSingle(ltg_id = 0, data = null) {
    try {
      const response = await wpClient.patch(
        `sitio/v1/listings/${ltg_id}`,
        data,
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async renewSingle(ltg_id = 0) {
    try {
      const response = await wpClient.patch(
        `sitio/v1/listings/${ltg_id}/renew`,
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async inquiry(data = null) {
    try {
      const response = await wpClient.post("sitio/v1/listing-inquiry/", data);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async promotion(data = null) {
    try {
      const response = await wpClient.post("sitio/v1/listing-promotion/", data);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async deleteListingImage(listind_id = 0, image_id = 0, conf = {}) {
    conf = _merge({}, conf);
    try {
      const response = await wpClient.delete(
        `sitio/v1/listings/${listind_id}/image/${image_id}`,
        conf,
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async postListingImageAsBlob(
    ltg_id = 0,
    filename = null,
    filetype = null,
    file = null,
    conf = {},
  ) {
    let defaultConf = _merge(
      {},
      {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Disposition": `attachment; filename=${filename}`,
          "Content-Type": filetype,
        },
      },
    );
    conf = _merge({}, defaultConf, conf);
    try {
      const response = await wpClient.post(
        `sitio/v1/listings/${ltg_id}/image/`,
        file,
        conf,
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
};
