<script>
import { mapGetters } from "vuex";
export default {
  components: {
    appBar: () =>
      import(/* webpackChunkName: "app-bar" */ "@/components/app-bar"),
    appBarMobile: () =>
      import(
        /* webpackChunkName: "app-bar-mobile" */ "@/components/app-bar-mobile"
      ),
    appSettings: () =>
      import(
        /* webpackChunkName: "app-settings" */ "@/components/app-settings"
      ),
    appAccount: () =>
      import(/* webpackChunkName: "app-account" */ "@/components/app-account"),
  },

  props: {
    showBack: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      appAccountOpen: false,
      appSettingsOpen: false,
    };
  },

  // created() {
  //   console.log("LAYOUT: Created");
  // },

  computed: {
    ...mapGetters("session", ["loggedIn"]),
    layout() {
      // let layout = this.$mq === "sm" ? "small" : "large";
      return `layout--${this.$mq}`;
    },
  },

  methods: {
    toggleAppAccount() {
      this.appAccountOpen = !this.appAccountOpen;
    },
    toggleAppSettings() {
      this.appSettingsOpen = !this.appSettingsOpen;
    },
  },
};
</script>

<template>
  <div :class="layout">
    <appBar
      v-if="$mq != 'sm'"
      @toggle-app-account="toggleAppAccount"
      @toggle-app-settings="toggleAppSettings"
    />
    <slot />
    <appBarMobile v-if="$mq == 'sm'" @toggle-app-account="toggleAppAccount" />
    <appSettings v-model="appSettingsOpen" />
    <appAccount v-if="loggedIn" v-model="appAccountOpen" />
  </div>
</template>
