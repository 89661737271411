import merge from "lodash/merge";
import { wpClient } from "../wp-client";

export default {
  async get(conf = {}) {
    conf = merge({}, conf);
    try {
      const response = await wpClient.get("sitio/v1/config/", conf);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async test(conf = {}) {
    conf = merge({}, conf);
    try {
      const response = await wpClient.get("sitio/v1/test/", conf);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
};
