<script>
import trapFocus from "@/directives/trapFocus";
import config from "@/utils/config";
import { removeElement } from "@/utils/helpers";
import svgClose from "@/svg/close";

export default {
  name: "SAuth",

  directives: {
    trapFocus,
  },

  components: {
    svgClose,
    login: () => import(/* webpackChunkName: "login" */ "./contents/login"),
    forgot: () => import(/* webpackChunkName: "forgot" */ "./contents/forgot"),
    recovery: () =>
      import(/* webpackChunkName: "recovery" */ "./contents/recovery"),
    activate: () =>
      import(/* webpackChunkName: "activate" */ "./contents/activate"),
    signup: () => import(/* webpackChunkName: "signup" */ "./contents/signup"),
  },

  props: {
    reauth: {
      type: Boolean,
    },
    action: {
      type: String,
      default: "login",
    },
    active: Boolean,
    component: [Object, Function],
    programmatic: Boolean,
    props: Object,
    events: Object,
    animation: {
      type: String,
      default: "zoom-out",
    },
    scroll: {
      type: String,
      default: "clip",
      validator: value => {
        return ["clip", "keep"].indexOf(value) >= 0;
      },
    },
    trapFocus: {
      type: Boolean,
      default: config.defaultTrapFocus,
    },
    customClass: String,
  },

  data() {
    return {
      isAction: this.action || "login",
      isActive: this.active || false,
      savedScrollTop: null,
      animating: true,
    };
  },

  computed: {
    actionClass() {
      return "sitioAuth--" + this.isAction;
    },
  },

  watch: {
    $route() {
      this.close();
    },
    active(value) {
      this.isActive = value;
    },
    isActive() {
      this.handleScroll();
    },
  },

  methods: {
    handleScroll() {
      if (typeof window === "undefined") return;

      if (this.scroll === "clip") {
        if (this.isActive) {
          document.documentElement.classList.add("is-clipped");
        } else {
          document.documentElement.classList.remove("is-clipped");
        }
        return;
      }

      this.savedScrollTop = !this.savedScrollTop
        ? document.documentElement.scrollTop
        : this.savedScrollTop;

      if (this.isActive) {
        document.body.classList.add("is-noscroll");
      } else {
        document.body.classList.remove("is-noscroll");
      }

      if (this.isActive) {
        document.body.style.top = `-${this.savedScrollTop}px`;
        return;
      }

      document.documentElement.scrollTop = this.savedScrollTop;
      document.body.style.top = null;
      this.savedScrollTop = null;
    },

    /**
     * Emit events, and destroy component if it's programmatic.
     */
    close() {
      this.$emit("close");
      this.$emit("update:active", false);
      // Timeout for the animation complete before destroying
      if (this.programmatic) {
        this.isActive = false;
        setTimeout(() => {
          this.$destroy();
          removeElement(this.$el);
        }, 150);
      }
    },

    /**
     * Keypress event that is bound to the document.
     */
    keyPress(event) {
      // Esc key
      if (this.isActive && event.keyCode === 27) this.close();
    },

    /**
     * Transition after-enter hook
     */
    afterEnter() {
      this.animating = false;
    },

    /**
     * Transition before-leave hook
     */
    beforeLeave() {
      this.animating = true;
    },
  },

  created() {
    if (typeof window !== "undefined") {
      document.addEventListener("keyup", this.keyPress);
    }
  },

  beforeMount() {
    // Insert the Auth comp in body tag
    // only if it's programmatic
    this.programmatic && document.body.appendChild(this.$el);
  },

  mounted() {
    if (this.programmatic) this.isActive = true;
    else if (this.isActive) this.handleScroll();
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      if (this.reauth) {
        if (!this.$store.getters["session/loggedIn"]) {
          this.$router.replace({ name: "home" });
        } else if (this.$route.query.next) {
          this.$router.replace(this.$route.query.next);
        }
        // else {
        //   this.$router.replace({ name: "account" });
        // }
      }
      // else if (this.$store.getters["session/loggedIn"]) {
      //   this.$router.push({ name: "account" });
      // }
      document.removeEventListener("keyup", this.keyPress);
      // reset scroll
      document.documentElement.classList.remove("is-clipped");
      const savedScrollTop = !this.savedScrollTop
        ? document.documentElement.scrollTop
        : this.savedScrollTop;
      document.body.classList.remove("is-noscroll");
      document.documentElement.scrollTop = savedScrollTop;
      document.body.style.top = null;
    }
  },
};
</script>

<template>
  <transition
    :name="animation"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
  >
    <div
      v-if="isActive"
      :id="actionClass"
      class="sitioModal sitioAuth is-active disable_touch_callout"
      :class="[customClass]"
    >
      <div class="sitioModal__background" @click="close()" />
      <div class="sitioModal__body">
        <button
          type="button"
          v-if="!animating"
          class="sitioModal__close"
          @click="close()"
        />
        <component
          class="sitioModal__content"
          :is="isAction"
          v-bind="props"
          v-trap-focus="trapFocus"
        >
        </component>
      </div>
    </div>
  </transition>
</template>
