import session from "./modules/session";
import account from "./modules/account";
import config from "./modules/config";
import media from "./modules/media";
// import posts from "./modules/posts";
import listings from "./modules/listings";
import organizations from "./modules/organizations";
import users from "./modules/users";
import plan from "./modules/plan";
import credits from "./modules/credits";
import gifts from "./modules/gifts";

export const wpService = {
  session,
  account,
  config,
  media,
  // posts,
  listings,
  users,
  organizations,
  plan,
  credits,
  gifts,
};
