import Vue from "vue";
import App from "./App.vue";
import store from "./stores";
import router from "./router";

Vue.config.productionTip = false;

export const sitioBus = new Vue();

import firebase from "firebase/app";
import "firebase/analytics";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyAow1peuw32kHoUDpaU-5-LjZ0XDO1FnAc",
  authDomain: "sitio-do.firebaseapp.com",
  databaseURL: "https://sitio-do.firebaseio.com",
  projectId: "sitio-do",
  storageBucket: "sitio-do.appspot.com",
  messagingSenderId: "405727387038",
  appId: "1:405727387038:web:c92886183a908496a20a8f",
  measurementId: "G-NVBK7NSPZ9",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// Vue.prototype.$analytics = firebase.analytics();

import VueMeta from "vue-meta";
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

import variables from "./styles/_variables.scss";
import VueMq from "vue-mq";
Vue.use(VueMq, {
  breakpoints: {
    // sm: 640,
    // md: 1024,
    sm: Number(variables.breakpoint_small),
    md: Number(variables.breakpoint_medium),
    lg: Infinity,
  },
  defaultBreakpoint: "sm", // this is for SSR
});

import PortalVue from "portal-vue";
Vue.use(PortalVue);

import Vue2TouchEvents from "vue2-touch-events";
Vue.use(Vue2TouchEvents);

import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, {
  siteKey: "6Lc7dcsUAAAAALOZBw-5nUT6UDkd9UBnFe99nQsM",
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true,
  },
});

import "./filters";

import "@/assets/icomoon/style.css";

import "@fortawesome/fontawesome-free/css/all.min.css";

import Loading from "@/components/loading";
Vue.use(Loading);

import Modal from "@/components/modal";
Vue.use(Modal);

import isMobile from "@/plugins/is-mobile";
Vue.use(isMobile);

import Mudal from "@/components/mudal";
Vue.use(Mudal);

import Auth from "@/components/auth";
Vue.use(Auth);

import Buefy from "buefy";
Vue.use(Buefy, {
  defaultIconPack: "fas",
});

import sitioLayout from "@/components/layout";
Vue.component("sitio-layout", sitioLayout);

import "simplebar/dist/simplebar.min.css";
import simplebar from "simplebar-vue";
Vue.component("simplebar", simplebar);

import appLoading from "@/components/app-loading";
Vue.component("appLoading", appLoading);

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
Vue.component("VueSlider", VueSlider);

import VueCountdown from "@chenfengyuan/vue-countdown";
Vue.component(VueCountdown.name, VueCountdown);

// import "./registerServiceWorker";
import wb from "./registerServiceWorker";
Vue.prototype.$workbox = wb;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
