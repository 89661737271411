import { wpClient } from "../wp-client";

export default {
  async get(id) {
    try {
      const response = await wpClient.get(`sitio/v1/gifts/${id}`);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async create(gift_recipient, gift_amount) {
    try {
      const response = await wpClient.post(`sitio/v1/gifts/`, {
        gift_recipient,
        gift_amount,
      });
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async accept(id) {
    try {
      const response = await wpClient.post(`sitio/v1/gifts/${id}/accept`);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
  async reject(id) {
    try {
      const response = await wpClient.post(`sitio/v1/gifts/${id}/reject`);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
};
