import _assign from "lodash/assign";
import { getField, updateField } from "vuex-map-fields";

const saved_settings = JSON.parse(localStorage.getItem("sitio_settings"));

const defaults_settings = {
  new_tab_for_listings: false,
};

const state = saved_settings
  ? _assign(defaults_settings, saved_settings)
  : defaults_settings;

export default {
  namespaced: true,

  state,

  getters: {
    getSetting(state) {
      return getField(state);
    },
  },

  mutations: {
    updateSetting(state, field) {
      updateField(state, field);
      localStorage.setItem("sitio_settings", JSON.stringify(state));
    },
  },
};
