import { wpClient } from "../wp-client";
import merge from "lodash/merge";
import appConfig from "@/app.config";

export default {
  async getData(conf = {}) {
    conf = merge({}, conf);
    try {
      const response = await wpClient.get("sitio/v1/account", conf);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },

  // Verify if username exist
  async usernameExist(username) {
    const body = {
      user_login: username,
    };
    try {
      const response = await wpClient.post("sitio/v1/username-exist", body);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data.message);
    }
  },

  // Verify if username exist
  async accountPatch(body) {
    try {
      const response = await wpClient.patch(appConfig.accountPatch, body);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data.message);
    }
  },

  // Password change
  async passwordChange(current_password, new_password) {
    const body = { current_password, new_password };
    try {
      const response = await wpClient.patch(appConfig.passwordChange, body);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },

  // Email change
  async emailChange(password, email) {
    const body = { password, email };
    try {
      const response = await wpClient.patch(appConfig.emailChange, body);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },

  // Request password reset key
  async recoveryRequest(user_login) {
    const body = { user_login };
    try {
      const response = await wpClient.post(appConfig.recoveryRequest, body);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },

  // Request password reset key
  async recoveryVerify(body) {
    try {
      const response = await wpClient.post(appConfig.recoveryVerify, body);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },

  // Change password
  async recoveryComplete(body) {
    try {
      const response = await wpClient.post(appConfig.recoveryComplete, body);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },

  async signupRequest(body) {
    try {
      const response = await wpClient.post(appConfig.signupRequest, body);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },

  // Verify signup validity
  async signupVerify(body) {
    try {
      const response = await wpClient.post(appConfig.signupVerify, body);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },

  // Confirm signup
  async signupComplete(body) {
    try {
      const response = await wpClient.post(appConfig.signupComplete, body);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },

  // Confirm signup
  async accountListingsQuery(conf = {}) {
    conf = merge({}, conf);
    try {
      const response = await wpClient.get("sitio/v1/account-listings/", conf);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },

  // async postAvatar(body) {
  //   try {
  //     const response = await wpClient.post("sitio/v1/account/avatar/", body);
  //     return Promise.resolve(response.data);
  //   } catch (error) {
  //     return Promise.reject(error.response.data);
  //   }
  // },

  async postAvatar(filename = null, filetype = null, file = null, conf = {}) {
    let defaultConf = merge(
      {},
      {
        headers: {
          "Content-Disposition": `attachment; filename=${filename}`,
          "Content-Type": filetype,
          "Cache-Control": "no-cache",
        },
      },
    );
    conf = merge({}, defaultConf, conf);
    try {
      const response = await wpClient.post(
        "sitio/v1/account/avatar/",
        file,
        conf,
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },

  // Confirm signup
  async deleteAvatar() {
    try {
      const response = await wpClient.delete("sitio/v1/account/avatar/");
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  },
};
