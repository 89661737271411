import { wpService } from "@/services/wp";
import _find from "lodash/find";
import _filter from "lodash/filter";

const sitio_config = JSON.parse(localStorage.getItem("sitio_config"));

export default {
  namespaced: true,

  state: {
    data: sitio_config ? sitio_config : null,
    // status: {
    //   updating: true,
    //   updated: true,
    //   failed: true
    // },
    updating: false,
  },

  getters: {
    isConfig(state) {
      return !!state.data;
    },
    config(state) {
      return state.data;
    },
    credits_bundles_options(state) {
      return state.data ? state.data.config_shop : [];
    },
    credits_bundles_find: (state, getters) => quantity => {
      return _find(getters.credits_bundles_options, { quantity: quantity });
    },
    temp_trades() {
      return {
        ltg_for_se: { label: "Venta" },
        ltg_for_sf: { label: "Venta (Amueblado)" },
        ltg_for_rde: { label: "Renta x Día" },
        ltg_for_rdf: { label: "Renta x Día (Amueblado)" },
        ltg_for_rme: { label: "Renta x Mes" },
        ltg_for_rmf: { label: "Renta x Mes (Amueblado)" },
      };
    },
    config_trades(state) {
      return state.data ? state.data.config_trades : [];
    },
    listings_categories(state) {
      return state.data ? state.data.config_listings_categories : [];
    },
    listings_conditions(state) {
      return state.data ? state.data.config_listings_conditions : [];
    },
    listings_amenities(state) {
      return state.data ? state.data.config_listings_amenities : [];
    },
    config_locations_full(state) {
      return state.data ? state.data.config_locations_full : [];
    },
    config_locations_groups(state) {
      return state.data ? state.data.config_locations_groups : [];
    },
    // config_locations_groups(state, getters) {
    //   if (!getters.config_locations_full.length)
    //     return getters.config_locations_full;
    // },
    property_rooms_max(state) {
      return state.data ? state.data.property_rooms_max : 0;
    },
    get_amenities: (state, getters) => ids => {
      return _filter(getters.listings_amenities, amenity => {
        return ids.includes(amenity.amenity_id);
      });
    },
    get_category: (state, getters) => category => {
      return getters.listings_categories[category];
    },
    get_condition: (state, getters) => condition => {
      return getters.listings_conditions[condition];
    },
    get_location: (state, getters) => id => {
      return _find(getters.config_locations_full, { loc_id: id });
    },
    user_punchline_length(state) {
      return state.data ? state.data.user_punchline_length : 100;
    },
    user_biography_length(state) {
      return state.data ? state.data.user_biography_length : 2000;
    },
  },

  actions: {
    async init({ state, dispatch }) {
      console.log("init: config", state);

      // let isServerAvailable = new Promise(resolve => {
      //   setTimeout(() => resolve("done!"), 10000);
      // });
      // await isServerAvailable;

      if (
        !state.data ||
        // 1000 * 60 * 60 < new Date().getTime() - state.data.last_update
        1 < new Date().getTime() - state.data.last_update
      ) {
        // console.log("get config");
        dispatch("update");
      }

      // const max_age = 1000 * 60 * 60;
      // const max_age = 1;

      // if (sitio_config === null) {
      //   dispatch("update");
      //   return;
      // }

      // if (sitio_config.last_update - new Date().getTime() > max_age) {
      //   return;
      // }
    },

    async update({ commit }) {
      commit("updating", true);
      await wpService.config
        .get()
        .then(response => {
          response.last_update = new Date().getTime();
          localStorage.setItem("sitio_config", JSON.stringify(response));
          commit("SET_CONFIG", response);
        })
        .catch(err => {
          console.log(err);
        });
      commit("updating", false);
    },
  },

  mutations: {
    SET_CONFIG(state, payload = null) {
      state.data = payload;
    },
    updating(state, value) {
      state.updating = value;
    },
  },
};
