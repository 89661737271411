<script>
import config from "@/utils/config";
import { removeElement } from "@/utils/helpers";
import svgClose from "@/svg/close";

export default {
  name: "SMudal",

  components: {
    svgClose,
  },

  props: {
    active: Boolean,
    component: [Object, Function],
    content: String,
    contentId: String,
    contentClass: String,
    programmatic: Boolean,
    props: Object,
    events: Object,
    isModal: Boolean,
    animation: {
      type: String,
      default: "zoom-out",
    },
    canCancel: {
      type: [Array, Boolean],
      default: () => {
        return config.defaultModalCanCancel;
      },
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
    scroll: {
      type: String,
      default: "clip",
      validator: value => {
        return ["clip", "keep"].indexOf(value) >= 0;
      },
    },
    customId: String,
    customClass: String,
  },

  data() {
    return {
      isActive: this.active || false,
      savedScrollTop: null,
      animating: true,
    };
  },

  computed: {
    cancelOptions() {
      return typeof this.canCancel === "boolean"
        ? this.canCancel
          ? config.defaultModalCanCancel
          : []
        : this.canCancel;
    },
    showX() {
      return this.cancelOptions.indexOf("x") >= 0;
    },
  },

  watch: {
    active(value) {
      this.isActive = value;
    },
    isActive(value) {
      this.handleScroll();
      this.$nextTick(() => {
        if (value && this.$el && this.$el.focus) {
          this.$el.focus();
        }
      });
    },
  },

  methods: {
    handleScroll() {
      if (typeof window === "undefined") return;

      if (this.scroll === "clip") {
        if (this.isActive) {
          document.documentElement.classList.add("is-clipped");
        } else {
          document.documentElement.classList.remove("is-clipped");
        }
        return;
      }

      this.savedScrollTop = !this.savedScrollTop
        ? document.documentElement.scrollTop
        : this.savedScrollTop;

      if (this.isActive) {
        document.body.classList.add("is-noscroll");
      } else {
        document.body.classList.remove("is-noscroll");
      }

      if (this.isActive) {
        document.body.style.top = `-${this.savedScrollTop}px`;
        return;
      }

      document.documentElement.scrollTop = this.savedScrollTop;
      document.body.style.top = null;
      this.savedScrollTop = null;
    },

    /**
     * Close the Modal if canCancel and call the onCancel prop (function).
     */
    cancel(method) {
      if (this.cancelOptions.indexOf(method) < 0) return;

      this.onCancel.apply(null, arguments);
      this.close();
    },

    /**
     * Emit events, and destroy component if it's programmatic.
     */
    close() {
      this.$emit("close");
      this.$emit("update:active", false);
      // Timeout for the animation complete before destroying
      if (this.programmatic) {
        this.isActive = false;
        setTimeout(() => {
          this.$destroy();
          removeElement(this.$el);
        }, 150);
      }
    },

    /**
     * Keypress event that is bound to the document.
     */
    keyPress(event) {
      // Esc key
      if (this.isActive && event.keyCode === 27) this.close();
    },

    /**
     * Transition after-enter hook
     */
    afterEnter() {
      this.animating = false;
    },

    /**
     * Transition before-leave hook
     */
    beforeLeave() {
      this.animating = true;
    },
  },

  created() {
    if (typeof window !== "undefined") {
      document.addEventListener("keyup", this.keyPress);
    }
  },

  beforeMount() {
    // Insert the Auth comp in body tag
    // only if it's programmatic
    this.programmatic && document.body.appendChild(this.$el);
  },

  mounted() {
    if (this.programmatic) this.isActive = true;
    else if (this.isActive) this.handleScroll();
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      document.removeEventListener("keyup", this.keyPress);
      // reset scroll
      document.documentElement.classList.remove("is-clipped");
      const savedScrollTop = !this.savedScrollTop
        ? document.documentElement.scrollTop
        : this.savedScrollTop;
      document.body.classList.remove("is-noscroll");
      document.documentElement.scrollTop = savedScrollTop;
      document.body.style.top = null;
    }
  },
};
</script>

<template>
  <portal to="mudal">
    <transition
      :name="animation"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
    >
      <div
        v-if="isActive"
        class="sitioMudal is-active disable_touch_callout"
        :id="customId"
        :class="[customClass]"
      >
        <div class="sitioMudal__background" @click="cancel('outside')" />
        <component
          class="sitioMudal__body"
          v-if="component"
          v-bind="props"
          v-on="events"
          :is="component"
          @close="close"
        />
        <div v-else-if="content" v-html="content" class="sitioMudal__body" />
        <div
          v-else
          class="sitioMudal__content"
          :class="contentClass"
          :id="contentId"
        >
          <slot />
        </div>
        <!-- <button
        type="button"
        v-if="showX && !animating"
        class="sitioMudal__close"
        @click="cancel('x')"
      /> -->
      </div>
    </transition>
  </portal>
</template>

<style lang="scss">
.sitioMudal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  .sitioMudal__background {
    transition: opacity 0.2s;
    pointer-events: none;
    opacity: 0;
    background-color: rgba(10, 10, 10, 0.86);
    // background-color: rgba(12, 18, 28, 0.38);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .sitioMudal__body,
  .sitioMudal__content {
    position: relative;
  }

  .sitioMudal__body {
    opacity: 0;
    transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1),
      transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 2s;
    // background-color: #fff;
    box-shadow: none;
    // box-shadow: 0 19px 60px rgba(0,0,0,.3), 0 15px 20px rgba(0,0,0,.22);

    overflow: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    will-change: scroll;
    -webkit-overflow-scrolling: touch;

    // height: 100vh;
    max-height: 100vh;
  }

  // @media (max-width: 39.9375em) {
  //   .sitioMudal__body {
  //     width: 100vw;
  //   }
  // }

  // @media (min-width: 40em) {
  //   .sitioMudal__body {
  //     position: relative;
  //     border-radius: 1rem;
  //     max-width: 444px;
  //     height: auto;
  //   }
  // }

  &.is-active {
    .sitioMudal__background {
      opacity: 0.6;
      opacity: 1;
      pointer-events: all;
    }
    .sitioMudal__body {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .sitioMudal__close {
    cursor: pointer;
    font-size: 1rem;
    background: none;
    width: 3rem;
    height: 3rem;
    position: absolute;
    // right: 20px;
    // top: 20px;
    right: 4px;
    top: 4px;
    border: none;
    padding: 0;
    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 45%;
      height: 5px;
      transform-origin: center center;
      background-color: #222;
    }
    &::after {
      transform: translateX(-50%) translateY(-50%) rotate(135deg);
    }
    &::before {
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
  }
}
</style>
